import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import { useQuery, gql } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { Textfit } from 'react-textfit';

import { useCampaignPage } from 'context/CampaignPage';
import cx from 'lib/cx';
import CampaignPageSectionHeader from './CampaignPageSectionHeader';

const GET_SPONSORS = gql`
  query GetCampaignPageSponsor($id: String!, $packageIds: SequelizeJSON!) {
    findCampaigns(id: $id) {
      id
      registrationTypes(where: { type: "sponsor", id: { in: $packageIds } }) {
        id
        name
        amount
      }

      sponsors(where: { registrationTypeId: { in: $packageIds } }) {
        id
        name
        logo
        website
        createdAt
        registrationTypeId
      }
    }
  }
`;

const GET_WIDGET = gql`
  query GetCampaignPageSponsorPackagesWidget($id: String!) {
    findCampaignWidgets(id: $id) {
      id
      title
      config
    }
  }
`;

const CampaignPageSponsors = ({ id, className }) => {
  const { campaignId } = useCampaignPage();

  const { data } = useQuery(GET_WIDGET, { variables: { id } });
  const block = data?.findCampaignWidgets[0];
  const packageIds = block?.config?.packages?.map((pkg) => pkg.registrationTypeId);

  const sponsorsQuery = useQuery(GET_SPONSORS, {
    variables: { id: campaignId, packageIds },
    skip: !block,
  });

  const sponsors = sponsorsQuery?.data?.findCampaigns?.[0]?.sponsors ?? [];
  const packages = sponsorsQuery?.data?.findCampaigns?.[0]?.registrationTypes ?? [];

  const sponsorsByPackage =
    orderBy(
      Object.entries(groupBy(sponsors, 'registrationTypeId')).map(([k, v]) => ({
        package: packages.find((pkg) => pkg.id === k),
        sponsors: orderBy(v, 'createdAt'),
      })),
      (x) => +x.package.amount,
      ['desc']
    ) ?? [];

  if (!block || sponsors.length === 0) return null;

  return (
    <div className={cx('py-16 bg-white', className)}>
      <div className="container max-w-7xl">
        {block.title && (
          <CampaignPageSectionHeader title={block.title} titleClassName="text-gray-800" />
        )}
        <div className="mt-10 space-y-5">
          {sponsorsByPackage.map((section) => {
            const size = block.config.packages.find(
              (x) => x.registrationTypeId === section.package.id
            )?.logoSize;

            if (section.sponsors.length === 0) return null;
            return (
              <div
                key={section.package.id}
                className="border border-gray-400 rounded-xl p-8 space-y-3"
              >
                <h2 className="text-center text-3xl font-medium">{section.package.name}</h2>
                <div className="flex flex-wrap justify-center">
                  {section.sponsors.map((sponsor) => (
                    <div
                      key={sponsor.id}
                      className={
                        {
                          large: 'basis-full sm:basis-1/2 md:basis-1/3 lg:basis-1/4',
                          medium: 'basis-1/2 sm:basis-1/3 md:basis-1/4 lg:basis-1/6',
                          small: 'basis-1/3 sm:basis-1/4 md:basis-1/6 lg:basis-[12.5%]',
                          extra_small: 'basis-1/4 sm:basis-1/6 md:basis-[12.5%] lg:basis-[10%]',
                        }[size]
                      }
                    >
                      <div
                        className={cx('aspect-video relative', {
                          'aspect-[3/2]': size === 'small',
                          'aspect-square': size === 'extra_small',
                        })}
                      >
                        <div
                          className={cx('absolute w-full h-full inset-0 p-4 md:p-6 lg:p-8', {
                            'lg:p-7': size === 'extra_small',
                            'md:px-2.5 lg:px-2.5': size === 'extra_small' && !sponsor.logo,
                            'md:p-4 lg:p-5': size !== 'extra_small' || !sponsor.logo,
                            'cursor-pointer': sponsor.website,
                          })}
                          role="presentation"
                          onClick={
                            sponsor.website
                              ? () => window.open(sponsor.website, '_blank')
                              : () => {}
                          }
                          key={sponsor.id}
                        >
                          {sponsor.logo ? (
                            <Image
                              publicId={sponsor.logo}
                              alt={sponsor.name}
                              className="block w-full h-full object-contain object-center"
                              width={800}
                              crop="fit"
                              fetchFormat="auto"
                              quality={100}
                            />
                          ) : (
                            <div
                              className={cx('w-full h-full bg-gray-200 rounded-xl p-2.5', {
                                'py-5': size === 'lg',
                              })}
                            >
                              <Textfit
                                className="w-full h-full flex items-center justify-center text-center font-medium"
                                max={
                                  {
                                    large: 24,
                                    medium: 20,
                                    small: 16,
                                    extra_small: 12,
                                  }[size]
                                }
                                min={6}
                              >
                                {sponsor.name}
                              </Textfit>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CampaignPageSponsors.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignPageSponsors.defaultProps = {
  className: '',
};

export default CampaignPageSponsors;
